export interface BlogPost {
    id: number;
    title: string;
    excerpt: string;
    content: string;
    date: string;
    author: string;
    tags: string[];
  }
  
  const blogPosts: BlogPost[] = [
    {
      id: 1,
      title: "Understanding High-Risk Pregnancies: What You Need to Know",
      excerpt: "Learn about the factors that contribute to high-risk pregnancies and how to manage them effectively.",
      content: "High-risk pregnancies require special attention and care. In this post, we'll discuss common factors that can lead to a high-risk pregnancy, such as advanced maternal age, pre-existing medical conditions, and multiple gestations. We'll also cover the importance of early prenatal care, regular check-ups, and lifestyle modifications to ensure the best possible outcomes for both mother and baby.",
      date: "2024-09-15",
      author: "Dr. Kanika Jain",
      tags: ["High-Risk Pregnancy", "Prenatal Care", "Maternal Health"]
    },
    {
      id: 2,
      title: "The Impact of PCOS on Fertility and How to Manage It",
      excerpt: "Discover the effects of Polycystic Ovary Syndrome (PCOS) on fertility and learn about treatment options.",
      content: "Polycystic Ovary Syndrome (PCOS) is a common endocrine disorder that affects many women of reproductive age. This blog post will explore how PCOS impacts fertility, including irregular ovulation and hormonal imbalances. We'll discuss various management strategies, from lifestyle changes to medical interventions, that can help women with PCOS improve their fertility and overall health.",
      date: "2024-09-22",
      author: "Dr. Kanika Jain",
      tags: ["PCOS", "Fertility", "Women's Health"]
    },
    {
      id: 3,
      title: "Laparoscopic Surgery: Minimally Invasive Techniques in Gynecology",
      excerpt: "Explore the benefits and applications of laparoscopic surgery in gynecological procedures.",
      content: "Laparoscopic surgery has revolutionized the field of gynecology, offering patients less invasive options for various procedures. This post will delve into the advantages of laparoscopic techniques, including shorter recovery times, reduced scarring, and lower risk of complications. We'll also discuss common gynecological conditions that can be treated laparoscopically, such as endometriosis, fibroids, and ovarian cysts.",
      date: "2024-09-29",
      author: "Dr. Kanika Jain",
      tags: ["Laparoscopy", "Minimally Invasive Surgery", "Gynecology"]
    },
    {
      id: 4,
      title: "Navigating Menopause: Symptoms, Treatments, and Lifestyle Tips",
      excerpt: "Learn about the menopausal transition and strategies for managing its symptoms effectively.",
      content: "Menopause is a natural phase in a woman's life, but it can bring about significant changes and challenges. This blog post will cover common menopausal symptoms, such as hot flashes, mood swings, and sleep disturbances. We'll explore various treatment options, including hormone replacement therapy and alternative remedies, as well as lifestyle modifications that can help women navigate this transition with greater ease and comfort.",
      date: "2024-10-06",
      author: "Dr. Kanika Jain",
      tags: ["Menopause", "Women's Health", "Hormone Therapy"]
    },
    {
      id: 5,
      title: "The Importance of Regular Health Check-Ups for Women",
      excerpt: "Understand why routine health screenings are crucial for maintaining overall well-being and preventing diseases.",
      content: "Regular health check-ups play a vital role in maintaining women's health and detecting potential issues early. This post will highlight the essential screenings women should undergo at different stages of life, including Pap smears, mammograms, and bone density tests. We'll also discuss the frequency of these check-ups and how they contribute to preventive care and early intervention for various health conditions.",
      date: "2024-10-13",
      author: "Dr. Kanika Jain",
      tags: ["Health Check-Up", "Preventive Care", "Women's Health"]
    },
    {
      id: 6,
      title: "Endometriosis: Recognizing Symptoms and Exploring Treatment Options",
      excerpt: "Learn about the signs of endometriosis and the various approaches to managing this condition.",
      content: "Endometriosis is a common yet often misunderstood condition affecting many women. This blog post will shed light on the symptoms of endometriosis, which can include severe menstrual pain, chronic pelvic pain, and fertility issues. We'll explore the diagnostic process and discuss a range of treatment options, from pain management and hormonal therapies to surgical interventions, helping women make informed decisions about their care.",
      date: "2024-10-20",
      author: "Dr. Kanika Jain",
      tags: ["Endometriosis", "Pelvic Pain", "Women's Health"]
    },
    {
      id: 7,
      title: "Adolescent Gynecology: Addressing Common Concerns and Promoting Health",
      excerpt: "Discover the unique gynecological needs of adolescents and how to approach their care sensitively.",
      content: "Adolescence is a critical time for establishing good gynecological health practices. This post will address common concerns in adolescent gynecology, such as irregular periods, acne, and sexual health education. We'll discuss the importance of creating a comfortable and confidential environment for young patients, as well as the role of vaccinations, particularly the HPV vaccine, in protecting long-term reproductive health.",
      date: "2024-10-27",
      author: "Dr. Kanika Jain",
      tags: ["Adolescent Health", "Gynecology", "Sexual Education"]
    },
    {
      id: 8,
      title: "Understanding Infertility: Causes, Diagnosis, and Treatment Options",
      excerpt: "Explore the factors contributing to infertility and the various approaches to overcoming fertility challenges.",
      content: "Infertility can be a challenging and emotional journey for many couples. This blog post will delve into the common causes of infertility in both men and women, including age-related factors, hormonal imbalances, and structural issues. We'll discuss the diagnostic process, including fertility tests and evaluations, and explore a range of treatment options, from lifestyle modifications to assisted reproductive technologies like IUI and IVF.",
      date: "2024-11-03",
      author: "Dr. Kanika Jain",
      tags: ["Infertility", "Reproductive Health", "Fertility Treatments"]
    },
    {
      id: 9,
      title: "The Benefits of Normal Delivery: What to Expect and How to Prepare",
      excerpt: "Learn about the advantages of natural childbirth and tips for preparing for a normal delivery.",
      content: "Normal delivery, or vaginal birth, offers numerous benefits for both mother and baby. This post will explore these advantages, including faster recovery times, reduced risk of respiratory problems for the newborn, and the promotion of early bonding. We'll provide tips on preparing for a normal delivery, including prenatal exercises, relaxation techniques, and pain management strategies, empowering expectant mothers to approach childbirth with confidence.",
      date: "2024-11-10",
      author: "Dr. Kanika Jain",
      tags: ["Normal Delivery", "Childbirth", "Prenatal Care"]
    },
    {
      id: 10,
      title: "Fibroids: Understanding Your Treatment Options",
      excerpt: "Discover the various approaches to managing uterine fibroids and choosing the right treatment for you.",
      content: "Uterine fibroids are common non-cancerous growths that can cause a range of symptoms in women. This blog post will provide an overview of fibroid types, symptoms, and diagnostic methods. We'll explore the spectrum of treatment options available, from watchful waiting and medication to minimally invasive procedures like uterine artery embolization and surgical interventions such as myomectomy or hysterectomy. The post will emphasize the importance of personalized treatment plans based on individual circumstances and preferences.",
      date: "2024-11-17",
      author: "Dr. Kanika Jain",
      tags: ["Fibroids", "Women's Health", "Gynecological Surgery"]
    }
  ];
  
  export default blogPosts;