import React, { useRef, useEffect, useState } from 'react';

interface CarouselItem {
  src: string;
  alt: string;
  title: string;
}

interface ResponsiveImageCarouselProps {
  items: CarouselItem[];
}

const ResponsiveImageCarousel: React.FC<ResponsiveImageCarouselProps> = ({ items }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.scrollWidth);
    }
  }, [items]);

  return (
    <div className="w-full overflow-hidden mt-12 mb-20 md:mb-12">
      {/* Mobile version (animated carousel) */}
      <div className="sm:hidden relative" ref={containerRef}>
        <div
          className="flex animate-marquee"
          style={{
            width: `${containerWidth}px`,
          }}
        >
          {items.map((item, index) => (
            <div key={index} className="flex-shrink-0 flex flex-col items-center mx-4">
              <div className="w-32 h-32 flex items-center justify-center">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
              <p className="mt-2 text-sm font-semibold text-center">{item.title}</p>
            </div>
          ))}
          {items.map((item, index) => (
            <div key={`repeat-${index}`} className="flex-shrink-0 flex flex-col items-center mx-4">
              <div className="w-32 h-32 flex items-center justify-center">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
              <p className="mt-2 text-sm font-semibold text-center">{item.title}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Desktop version (static row) */}
      <div className="hidden sm:flex justify-center items-center space-x-20">
        {items.map((item, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="w-40 h-40 flex items-center justify-center">
              <img
                src={item.src}
                alt={item.alt}
                className="max-w-full max-h-full object-contain"
              />
            </div>
            <p className="mt-2 text-base font-semibold text-center">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResponsiveImageCarousel;

