
import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

const ScatteredPurpleBackground: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const bubbles = [
    { size: 300, x: '10%', y: '20%', delay: 0 },
    { size: 200, x: '80%', y: '60%', delay: 0.2 },
    { size: 150, x: '50%', y: '30%', delay: 0.4 },
    { size: 100, x: '70%', y: '80%', delay: 0.6 },
    { size: 80, x: '20%', y: '70%', delay: 0.8 },
  ];

  return (
    <div className="relative min-h-screen overflow-hidden bg-gradient-to-br from-purple-50 to-indigo-50">
      {bubbles.map((bubble, index) => (
        <motion.div
          key={index}
          className="absolute rounded-full opacity-70 mix-blend-multiply"
          style={{
            width: bubble.size,
            height: bubble.size,
            left: bubble.x,
            top: bubble.y,
            background: `radial-gradient(circle, rgba(167,139,250,0.7) 0%, rgba(139,92,246,0.4) 50%, rgba(124,58,237,0.1) 100%)`,
          }}
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0.7, 0.9, 0.7],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            delay: bubble.delay,
          }}
        />
      ))}
      <div 
        className="absolute inset-0 opacity-30"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
          backgroundSize: '200px 200px',
          mixBlendMode: 'soft-light'
        }}
      />
      <motion.div
        className="absolute inset-0"
        style={{
          background: 'linear-gradient(45deg, rgba(168,85,247,0.2) 0%, rgba(236,72,153,0.2) 100%)',
        }}
        animate={{
          opacity: [0.2, 0.3, 0.2],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
        }}
      />
      {children && <div className="relative z-10">{children}</div>}
    </div>
  );
};

export default ScatteredPurpleBackground;