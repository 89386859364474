import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

const testimonials = [
  {
    avatarImage: "/images/avatar1.png",
    name: "Riya Sharma",
    rating: 5,
    review: "She is the best clinician and surgeon. Her treatment is always up to the mark and her way of counseling and dealing with the patients is extraordinary."
  },
  {
    avatarImage: "/images/avatar2.png",
    name: "Ruby Jaspreet",
    rating: 5,
    review: "Dr. Kanika Jain is one of the best doctors I've come across. She is incredibly patient, explains everything in detail and I found her to be always accessible. She and her team of doctors at Ganga Ram hospital, including Dr. Ila Sharma, inspire in you trust and confidence, something that is absolutely essential between a patient and a doctor!"
  },
  {
    avatarImage: "/images/avatar3.png",
    name: "Anju Sharma",
    rating: 5,
    review: "Very polite Doctor I have ever seen. Explain each and every thing very nicely"
  },
  {
    avatarImage: "/images/avatar4.png",
    name: "Shweta Chadda",
    rating: 5,
    review: "Best Doctor ever. Thanks a lot mam"
  },
  {
    avatarImage: "/images/avatar5.png",
    name: "Sunita Siwal",
    rating: 5,
    review: "Best Doctor in NCR"
  }
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <motion.section
      id="testimonials"
      className="bg-[#faf9f5] py-16 px-4 sm:px-6 lg:px-8 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-5xl text-purple-900 font-fraunces-slab text-center mb-12">
          What Our Patients Say
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 relative">
          <div className="col-span-1 shadow-2xl rounded-full">
            <img
              src="/images/testimonial.png"
              alt="Dr. Kanika Jain"
              className="w-full h-full object-cover filter brightness-30"
            />
          </div>
          <div className="lg:col-span-2 relative">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentIndex}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg shadow-xl p-6 md:p-8 h-full"
              >
                <div className="flex flex-col h-full">
                  <div className="flex items-center mb-4">
                    <img
                      src={testimonials[currentIndex].avatarImage}
                      alt={testimonials[currentIndex].name}
                      className="w-16 h-16 rounded-full mr-4"
                    />
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">
                        {testimonials[currentIndex].name}
                      </h3>
                      <div className="flex items-center">
                        {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                          <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                        ))}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-600 italic flex-grow overflow-y-auto mb-4">
                    "{testimonials[currentIndex].review}"
                  </p>
                  <div className="flex justify-between items-center mt-auto">
                    <button
                      onClick={handlePrev}
                      className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
                    >
                      <ChevronLeft className="w-6 h-6 text-gray-600" />
                    </button>
                    <div className="flex space-x-2">
                      {testimonials.map((_, index) => (
                        <button
                          key={index}
                          onClick={() => setCurrentIndex(index)}
                          className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-purple-600' : 'bg-gray-300'
                            }`}
                        />
                      ))}
                    </div>
                    <button
                      onClick={handleNext}
                      className="bg-white rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
                    >
                      <ChevronRight className="w-6 h-6 text-gray-600" />
                    </button>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default TestimonialCarousel;