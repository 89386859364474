import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import servicesData from '../utility/services';

const Header: React.FC = () => {
   const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
   const [isScrolled, setIsScrolled] = useState<boolean>(false);
   const [isServicesOpen, setIsServicesOpen] = useState<boolean>(false);

   const location = useLocation();

   const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
   };

   const toggleServices = () => {
      setIsServicesOpen(!isServicesOpen);
   };

   useEffect(() => {
      const handleResize = () => {
         if (window.innerWidth >= 768 && isMenuOpen) {
            setIsMenuOpen(false);
         }
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, [isMenuOpen]);

   useEffect(() => {
      const handleScroll = () => {
         setIsScrolled(window.scrollY > 0);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
         window.removeEventListener('scroll', handleScroll);
      };
   }, []);

   const handleLinkClick = () => {
      setIsMenuOpen(false);
      setIsServicesOpen(false);
   };

   return (
      <header className={`w-full font-fraunces-slab fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}>
         <div className="container mx-auto px-4">
            <nav className="flex justify-between items-center py-4">
               <Link to="/" className="flex items-center">
                  <svg className="w-10 h-10 md:w-12 md:h-12 lg:w-16 lg:h-16" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <circle cx="50" cy="50" r="48" stroke="#333" strokeWidth="4" />
                     <text x="50" y="50" fontFamily="Arial" fontSize="40" fill="#333" textAnchor="middle" dominantBaseline="central">KJ</text>
                  </svg>
                  <span className="ml-2 text-2xl md:text-4xl lg:text-5xl font-semibold text-gray-800">Dr. Kanika Jain</span>
               </Link>

               <button
                  className="md:hidden z-20"
                  onClick={toggleMenu}
                  aria-label="Toggle menu"
               >
                  <svg className="w-6 h-6" fill="none" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
                  </svg>
               </button>

               {isMenuOpen && (
                  <div className="md:hidden absolute top-16 left-0 right-0 bg-white z-50 flex flex-col max-h-screen overflow-y-auto shadow-lg">
                     <div className="p-4 flex flex-col space-y-4">
                        <Link
                           to="/"
                           className="text-foreground hover:text-gray-600 text-lg font-semibold transition-colors"
                           onClick={handleLinkClick}
                        >
                           Home
                        </Link>
                        <Link
                           to="/about"
                           className="text-foreground hover:text-gray-600 text-lg font-semibold transition-colors"
                           onClick={handleLinkClick}
                        >
                           About
                        </Link>
                        <div>
                           <button
                              onClick={toggleServices}
                              className="text-foreground hover:text-gray-600 text-lg font-semibold transition-colors flex items-center justify-between w-full"
                           >
                              Services
                              <svg
                                 className={`w-4 h-4 ml-2 transform ${isServicesOpen ? 'rotate-180' : ''}`}
                                 fill="none"
                                 stroke="currentColor"
                                 viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                              </svg>
                           </button>
                           {isServicesOpen && (
                              <div className="ml-4 mt-2 space-y-2">
                                 {Object.entries(servicesData).map(([key, service]) => (
                                    <Link
                                       key={key}
                                       to={`/services/${service.slug}`}
                                       className="block text-gray-600 hover:text-purple-600 text-base transition-colors"
                                       onClick={handleLinkClick}
                                    >
                                       {service.title}
                                    </Link>
                                 ))}
                              </div>
                           )}
                        </div>

                        <Link
                           to="/blogs"
                           className="text-foreground hover:text-gray-600 text-lg font-semibold transition-colors"
                           onClick={handleLinkClick}
                        >
                           Blogs
                        </Link>


                        <Link
                           to="/contact"
                           className="text-foreground hover:text-gray-600 text-lg font-semibold transition-colors"
                           onClick={handleLinkClick}
                        >
                           Contact
                        </Link>
                     </div>
                  </div>
               )}

               {/* Desktop menu */}
               <div className="hidden md:flex md:items-center md:justify-center">
                  <div className="flex flex-col md:flex-row md:items-center md:space-x-8 space-y-4 md:space-y-0 ml-8 md:ml-0">
                     <Link
                        to="/"
                        className={`text-gray-800 hover:text-foreground transition-colors text-xl font-semibold ${location.pathname === '/' ? 'border-b-2 border-white rounded-md bg-purple-600 text-white px-4 py-2' : ''}`}
                     >
                        Home
                     </Link>
                     <Link
                        to="/about"
                        className={`text-gray-800 hover:text-foreground transition-colors text-xl font-semibold ${location.pathname === '/about' ? 'border-b-2 border-white rounded-md bg-purple-600 text-white px-4 py-2' : ''}`}
                     >
                        About
                     </Link>
                     <div className="relative group">
                        <Link
                           to="/services"
                           className={`text-gray-800 hover:text-foreground transition-colors text-xl font-semibold ${location.pathname === '/services' ? 'border-b-2 border-white rounded-md bg-purple-600 text-white px-4 py-2' : ''}`}
                        >
                           Services
                        </Link>
                        <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50">
                           {Object.entries(servicesData).map(([key, service]) => (
                              <Link
                                 key={key}
                                 to={`/services/${service.slug}`}
                                 className="block px-4 py-2 text-sm text-gray-700 hover:bg-purple-600 hover:text-white transition-colors"
                              >
                                 {service.title}
                              </Link>
                           ))}
                        </div>
                     </div>
                     <Link
                        to="/blogs"
                        className={`text-gray-800 hover:text-foreground transition-colors text-xl font-semibold ${location.pathname === '/blogs' ? 'border-b-2 border-white rounded-md bg-purple-600 text-white px-4 py-2' : ''}`}
                        onClick={handleLinkClick}
                     >
                        Blogs
                     </Link>
                     <Link
                        to="/contact"
                        className={`text-gray-800 hover:text-foreground transition-colors text-xl font-semibold ${location.pathname === '/contact' ? 'border-b-2 border-white rounded-md bg-purple-600 text-white px-4 py-2' : ''}`}
                     >
                        Contact
                     </Link>
                  </div>
               </div>
            </nav>
         </div>
      </header>
   );
};

export default Header;