import React from "react";
import './index.css';
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Layout from "./components/Layout/Layout";
import ServiceTemplatePage from "./components/utility/ServicePageTemplate";
import Blogs from "./pages/Blogs";
import BlogPostTemplate from "./components/blog/BlogPostTemplate";

// Custom hook for scroll restoration
const useScrollToTop = () => {
  const { pathname } = useLocation();
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const App: React.FC = () => {
  useScrollToTop();

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />}  />
        <Route path='/services/:slug' element={<ServiceTemplatePage />} />
        <Route path="/blogs" element={<Blogs />} /> 
        <Route path="/blogs/:id" element={<BlogPostTemplate />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Layout>
  )
}

export default App;