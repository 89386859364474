import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaEnvelope, FaPhone, FaMapMarkerAlt, FaAward, FaUserMd, FaCertificate } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gradient-to-b from-purple-900 to-indigo-900 text-white py-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h3 className="text-2xl font-bold mb-6 text-white">Dr. Kanika Jain</h3>
            <p className="mb-4 text-gray-300">Obstetrician, Gynecologist, Infertility Specialist & Laparoscopic Surgeon</p>
            <p className="mb-4 text-gray-300">28+ years of experience</p>
            <div className="flex space-x-4">
              <a href="/#" className="text-purple-300 hover:text-white transition-colors duration-300">
                <FaFacebookF size={20} />
              </a>
              <a href="/#" className="text-purple-300 hover:text-white transition-colors duration-300">
                <FaTwitter size={20} />
              </a>
              <a href="/#" className="text-purple-300 hover:text-white transition-colors duration-300">
                <FaLinkedinIn size={20} />
              </a>
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3 className="text-2xl font-bold mb-6 text-white">Contact</h3>
            <p className="flex items-center mb-3 text-gray-300">
              <FaMapMarkerAlt className="mr-3 text-purple-400" />
              Dr Jain's Clinic, 3842, Arya Samaj Rd, Karol Bagh, New Delhi, Delhi 110005
            </p>
            <p className="flex items-center mb-3 text-gray-300">
              <FaPhone className="mr-3 text-purple-400" />
              +011-42251764 (Mon, Wed, Fri: 4 pm - 6 pm)
            </p>
            <p className="flex items-center mb-3 text-gray-300">
              <FaPhone className="mr-3 text-purple-400" />
              +011-42251765 (Tue, Thu, Sat: 12 Noon - 2 pm)
            </p>
            <p className="flex items-center mb-3 text-gray-300">
              <FaEnvelope className="mr-3 text-purple-400" />
              dr.kanika@gmail.com
            </p>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3 className="text-2xl font-bold mb-6 text-white">Qualifications</h3>
            <ul className="space-y-2 text-gray-300">
              <li className="flex items-center">
                <FaUserMd className="mr-3 text-purple-400" />
                MBBS, DGO, DNB, FICOG, FICMCH
              </li>
              <li className="flex items-center">
                <FaCertificate className="mr-3 text-purple-400" />
                Fellowship in Endoscopic Surgery (2018)
              </li>
              <li className="flex items-center">
                <FaCertificate className="mr-3 text-purple-400" />
                Fellowship in Gynae Robotic Surgery (2018)
              </li>
              <li className="flex items-center">
                <FaCertificate className="mr-3 text-purple-400" />
                Certified Robotic Surgeon from EEC Paris (2014)
              </li>
              <li className="flex items-center">
                <FaCertificate className="mr-3 text-purple-400" />
                Fellowship in Gynae Robotic Surgery (2012)
              </li>
              <li className="flex items-center">
                <FaCertificate className="mr-3 text-purple-400" />
                Fellowship in Endoscopic Surgery(2008)
              </li>
            </ul>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h3 className="text-2xl font-bold mb-6 text-white">Achievements</h3>
            <ul className="space-y-2 text-gray-300">
              <li className="flex items-center">
                <FaAward className="mr-3 text-purple-400" />
                APJ Abdul Kalam award for proficiency in gynae
              </li>
             
              <li className="flex items-center">
                <FaAward className="mr-3 text-purple-400" />
                Chairperson AOGD Endoscopy Committee 2021-23
              </li>
              <li className="flex items-center">
                <FaAward className="mr-3 text-purple-400" />
                Secretary of NARCHI 2024-26
              </li>
             
              <li className="flex items-center">
                <FaAward className="mr-3 text-purple-400" />
                Vice President AOGD 2020-21
              </li>
            </ul>
          </motion.div>
        </div>
        
        <motion.div 
          className="mt-12 pt-8 border-t border-purple-700 text-center text-gray-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <p className="mb-4">&copy; {new Date().getFullYear()} Dr. Kanika Jain. All rights reserved.</p>
          <p className="text-sm">
            Website by <a href="https://rnahealthtech.com" className="text-purple-300 hover:underline">RNA HealthTech</a>
          </p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
