import React from 'react';
import { useParams } from 'react-router-dom';
import blogPosts from './blog'; 

const BlogPostTemplate: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  
  const post = blogPosts.find(post => post.id === parseInt(id as string));

  if (!post) {
    return <div className="container mx-auto px-4 py-12">Blog post not found</div>;
  }

  return (
    <article className="container mx-auto px-4 py-12 mt-24">
      <h1 className="text-4xl font-fraunces-slab mb-4">{post.title}</h1>
      <div className="mb-6">
        <span className="text-gray-600 mr-4">{new Date(post.date).toLocaleDateString()}</span>
        <span className="text-purple-600">By {post.author}</span>
      </div>
      <div className="prose max-w-none mb-8">
        {post.content.split('\n').map((paragraph, index) => (
          <p key={index} className="mb-4">{paragraph}</p>
        ))}
      </div>
      <div className="flex flex-wrap gap-2">
        {post.tags.map((tag, index) => (
          <span key={index} className="text-sm bg-purple-100 text-purple-800 px-3 py-1 rounded-full">
            {tag}
          </span>
        ))}
      </div>
    </article>
  );
};

export default BlogPostTemplate;