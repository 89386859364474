import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Heart, Award } from 'lucide-react';

const EmpoweredText: React.FC = () => {
  const features = [
    { icon: Shield, text: "Expert care in gynecology and obstetrics" },
    { icon: Heart, text: "Compassionate approach to women's health" },
    { icon: Award, text: "28+ years of experience and numerous accolades" },
  ];

  return (
    <motion.div 
      className="w-full lg:w-3/4 mx-auto px-4 py-8 bg-purple-50 rounded-xl shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-2xl sm:text-3xl font-bold text-purple-800 text-center mb-6">
        Empowering Women Through Expert Healthcare
      </h3>
      
      <div className="space-y-6 text-gray-700">
        <p className="text-sm sm:text-base leading-relaxed">
          <span className="font-semibold text-purple-700">Dr. Kanika Jain</span> stands as a beacon of excellence in women's healthcare. With over 28 years of experience, she has dedicated her career to empowering women through expert medical care and compassionate support.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center space-x-3 bg-white p-4 rounded-lg shadow">
              <feature.icon className="text-purple-600 flex-shrink-0" size={24} />
              <span className="text-sm">{feature.text}</span>
            </div>
          ))}
        </div>
        
        <div className="bg-purple-100 p-4 rounded-lg">
          <p className="text-sm sm:text-base leading-relaxed text-purple-900">
            Dr. Jain's approach combines cutting-edge medical expertise with a deep understanding of women's unique health needs. She provides a safe, supportive environment where every patient receives personalized care tailored to their individual circumstances.
          </p>
        </div>
      </div>
      
      <div className="mt-6">
        <p className="text-base sm:text-lg font-semibold text-purple-800 mb-3">
          Choose Dr. Kanika Jain for:
        </p>
        <ul className="list-none space-y-2">
          {["Comprehensive gynecological care", "High-risk pregnancy management", "Advanced laparoscopic and robotic surgeries"].map((item, index) => (
            <li key={index} className="flex items-center text-sm sm:text-base">
              <motion.span 
                className="text-purple-600 mr-2"
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                •
              </motion.span>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default EmpoweredText;