import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import blogPosts from "../blog/blog";

interface BlogCardProps {
  post: typeof blogPosts[0];
}

const BlogCard: React.FC<BlogCardProps> = ({ post }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blogs/${post.id}`);
  };

  return (
    <motion.div
      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
      whileHover={{ scale: 1.02 }}
      onClick={handleClick}
    >
      <div className="p-6 text-center sm:text-left">
        <h3 className="text-xl font-fraunces-slab mb-2 text-purple-800">{post.title}</h3>
        <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>
        <div className="flex flex-wrap gap-2 justify-center sm:justify-start">
          {post.tags.slice(0, 2).map((tag, index) => (
            <span key={index} className="text-xs bg-purple-100 text-purple-800 px-2 py-1 rounded-full">
              {tag}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

const BlogPreview: React.FC = () => {
  const navigate = useNavigate();
  const featuredPosts = blogPosts.slice(0, 6);

  return (
    <section className="py-16 bg-gradient-to-b from-rose-50 to-yellow-50">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl sm:text-4xl md:text-5xl mb-8 text-purple-900 font-fraunces-slab text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Research & Insights
        </motion.h2>

        <div className="flex flex-col lg:flex-row items-center lg:items-start mb-16">
          <motion.div
            className="lg:w-1/3 mb-8 lg:mb-0 lg:pr-8 text-center lg:text-left"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <img
              src="/images/blogpreview.jpg"
              alt="Dr. Kanika Jain writing"
              className="rounded-lg shadow-lg mb-4 w-full max-w-md mx-auto"
            />
            <h3 className="text-2xl font-fraunces-slab text-purple-800 mb-2">Dr. Kanika's Passion</h3>
            <p className="text-gray-700">
              Dr. Kanika Jain is dedicated to advancing women's health through her extensive research and insightful writing. Her articles provide valuable information and expert advice on various gynecological topics.
            </p>
          </motion.div>
          
          <motion.div
            className="lg:w-2/3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {featuredPosts.map((post) => (
              <BlogCard key={post.id} post={post} />
            ))}
          </motion.div>
        </div>

        <div className="text-center">
          <motion.button
            onClick={() => navigate("/blogs")}
            className="bg-purple-600 hover:bg-purple-700 transition-colors text-xl text-white font-semibold px-8 py-3 rounded-lg shadow-xl"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            View All Blog Posts
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default BlogPreview;