import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence, useInView } from "framer-motion";
import servicesData from "./services";
import { ServiceCard } from "../../pages/Services";
import ScatteredPurpleBackground from "../../ScatteredPurpleBackground";
import EmpoweredText from "./EmpoweredText";


const ServicesPreview: React.FC = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedService, setSelectedService] = useState<[string, any]>(['', {}]);

    const serviceEntries = Object.entries(servicesData);

    const ref = useRef(null);

    const isInView = useInView(ref, { once: true, amount: 0.1 });

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % serviceEntries.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [serviceEntries.length]);

    useEffect(() => {
        setSelectedService(serviceEntries[0]);
        //eslint-disable-next-line
    }, []);

    return (
        <ScatteredPurpleBackground>
            <motion.section
                className="container mx-auto px-4 py-12 md:py-24"
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                
            >
                <motion.h2
                    className="text-3xl sm:text-4xl md:text-5xl mb-8 text-purple-900 font-fraunces-slab text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}>
                    Our Services
                </motion.h2>

                <EmpoweredText />

                {/* Mobile view */}
                <div className="md:hidden mt-8">
                    <AnimatePresence initial={false} custom={1}>
                        <motion.div
                            key={currentIndex}
                            className="w-full max-w-sm mx-auto"
                        >
                            <ServiceCard
                                service={serviceEntries[currentIndex][1]}
                                path={`/services/${serviceEntries[currentIndex][0]}`}
                            />
                        </motion.div>
                    </AnimatePresence>
                </div>

                {/* Desktop view */}
                <div className="hidden md:flex flex-col lg:flex-row gap-8 mt-12">
                    <div className="w-full lg:w-1/2 grid grid-cols-2 gap-6">
                        {serviceEntries.slice(0, 4).map(([key, service], index) => (
                            <motion.div
                                key={key}
                                initial={{ opacity: 0, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                onClick={() => setSelectedService([key, service])}
                            >
                                <ServiceCard
                                    service={service}
                                    path={`/services/${key}`}
                                />
                            </motion.div>
                        ))}
                    </div>
                    <div className="w-full lg:w-1/2 bg-white p-6 rounded-2xl shadow-lg">
                        <motion.div
                            key={selectedService[0]}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                             
                        >
                            <img src={selectedService[1].image} alt={selectedService[1].title} className="w-full h-64 object-cover mb-4 rounded-xl" />
                            <h3 className="text-2xl font-bold mb-3 text-purple-800">{selectedService[1].title}</h3>
                            <p className="mb-4 text-gray-600">{selectedService[1].description}</p>
                            <div className="space-y-4">
                                <div>
                                    <h4 className="font-bold mb-2 text-purple-700">Symptoms:</h4>
                                    <ul className="list-disc list-inside text-gray-700">
                                        {selectedService[1].symptoms?.map((symptom: string, index: number) => (
                                            <li key={index}>{symptom}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <h4 className="font-bold mb-2 text-purple-700">Treatments:</h4>
                                    <ul className="list-disc list-inside text-gray-700">
                                        {selectedService[1].treatments?.map((therapy: string, index: number) => (
                                            <li key={index}>{therapy}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>

                <div className="text-center mt-12">
                    <motion.button
                        onClick={() => navigate("/services")}
                        className="bg-purple-600 hover:bg-purple-700 transition-colors text-xl text-white font-semibold px-8 py-3 rounded-lg shadow-xl"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      
                    >
                        View All Services
                    </motion.button>
                </div>
            </motion.section>
        </ScatteredPurpleBackground>
    );
};

export default ServicesPreview;