import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { motion } from 'framer-motion';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock, FaStethoscope } from 'react-icons/fa';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useForm, ValidationError } from '@formspree/react';

// add this import for the marker icon
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { AnimatedWord } from './Home';
import ScatteredPurpleBackground from '../ScatteredPurpleBackground';
import AppointmentModal from '../components/utility/AppointmentModal';
import { ArrowDownCircle, Mail } from 'lucide-react';
import SEOMetaTags from '../components/utility/SEOMetaTags';
import OnlineModal from '../components/utility/OnlineAppointment';



// Create a custom icon
const customIcon = L.icon({
   iconUrl: markerIcon,
   iconRetinaUrl: markerIcon2x,
   shadowUrl: markerShadow,
   iconSize: [25, 41],
   iconAnchor: [12, 41],
   popupAnchor: [1, -34],
   tooltipAnchor: [16, -28],
   shadowSize: [41, 41]
});



const Contact: React.FC = () => {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [])

   const position: [number, number] = [28.6385285, 77.18956850];
   const mapRef = useRef<HTMLDivElement>(null);

   const [isModalOpen, setIsModalOpen] = useState(false);
   const openModal = () => setIsModalOpen(true);
   const closeModal = () => setIsModalOpen(false);

   const [isModalOpen2, setIsModalOpen2] = useState(false);
   const openModal2 = () => setIsModalOpen2(true);
   const closeModal2 = () => setIsModalOpen2(false);


   const scrollToMap = () => {
      mapRef.current?.scrollIntoView({ behavior: 'smooth' });
   };


   const [state, handleSubmit] = useForm('xovqqvee');
   const [formData, setFormData] = useState({
      name: '',
      phone: '',
      email: '',
      appointmentType: 'offline',
      date: '',
      message: '',
   });

   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData(prevData => ({
         ...prevData,
         [name]: value
      }));
   };

   const handleFinalSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      handleSubmit(formData);
      sendWhatsAppMessage();
   };


   const sendWhatsAppMessage = () => {
      const message = `New Appointment Request:
   Name: ${formData.name}
   Phone: ${formData.phone}
   Email: ${formData.email}
   Appointment : ${formData.appointmentType}
   Date: ${formData.date}
   Message: ${formData.message}`;

      const whatsappNumber = '919811022255';
      const encodedMessage = encodeURIComponent(message);
      const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
      window.open(whatsappUrl, '_blank');
   }


   console.log(state);

   return (
      <>
         <SEOMetaTags page='contact' />
         <main className='flex-grow'>

            <ScatteredPurpleBackground>

               <div className='flex flex-col md:flex-row min-h-screen relative'>
                  <div className='absolute inset-0 md:hidden'>
                     <div
                        className='w-full h-full bg-cover bg-center'
                        style={{
                           backgroundImage: "url('/images/babymom.jpg')",
                           filter: "brightness(0.7)"
                        }}
                     >

                     </div>

                  </div>
                  <motion.section
                     className="w-full md:w-1/2 flex items-center justify-center px-6 py-12 mt-20 md:mt-0 md:py-24 relative z-10"
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ duration: 0.6 }}
                  >

                     <div className="max-w-xl">
                        <h1 className="font-fraunces-slab text-4xl md:text-5xl lg:text-6xl leading-tight mb-6 text-white md:text-zinc-900 ml-5 lg:ml-48">
                           <span className="block md:inline">Connect with</span>
                           <span className="block md:ml-2">Dr. Kanika Jain</span>
                           <span className="block md:inline md:ml-2">From</span>
                           <motion.span
                              initial="hidden"
                              animate="visible"
                              variants={{
                                 hidden: {},
                                 visible: {
                                    transition: {
                                       staggerChildren: 0.8
                                    }
                                 }
                              }}
                              className="block md:inline"
                           >
                              <span className="block md:inline md:ml-2"><AnimatedWord word="Discussions" /></span>
                              <span className="block md:inline md:ml-2"><AnimatedWord word="to" /></span>
                              <span className="block md:inline md:ml-2"><AnimatedWord word="Recovery" /></span>
                           </motion.span>
                        </h1>


                        <motion.div
                           className="mt-8 flex flex-wrap mt-20 items-center space-x-2 md:space-x-4 ml-5 lg:ml-48"
                           initial={{ opacity: 0, y: 20 }}
                           animate={{ opacity: 1, y: 0 }}
                           transition={{ duration: 0.6, delay: 0.3 }}
                        >
                           <button
                              className="inline-flex items-center px-4 py-2 md:px-6 md:py-3 text-md md:text-xl lg:text-xl font-semibold text-white md:text-black bg-purple-600 md:bg-transparent rounded-lg mb-2 md:mb-0"
                              onClick={openModal}
                           >
                              Get Consultation
                              <ArrowDownCircle className="ml-2 w-4 h-4 md:w-6 md:h-6" />
                           </button>
                           <a href="mailto:dr.kanika@gmail.com" className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-purple-600 rounded-full mb-2 md:mb-0">
                              <Mail className="w-5 h-5 md:w-6 md:h-6 text-white" />
                           </a>
                           <a href="#" onClick={openModal2} className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-green-500 rounded-full mb-2 md:mb-0">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 md:w-7 md:h-7 fill-current text-white">
                                 <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.890-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                              </svg>
                           </a>
                        </motion.div>
                        <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
                        <OnlineModal isOpen2={isModalOpen2} onClose2={closeModal2} />
                     </div>
                  </motion.section>

                  {/* Image Section */}
                  {/* Image Section (visible on desktop) */}
                  <motion.section
                     className="hidden md:block w-1/2 h-screen relative"
                     initial={{ opacity: 0, x: 50 }}
                     animate={{ opacity: 1, x: 0 }}
                     transition={{ duration: 0.9, delay: 0.3 }}
                  >
                     <img
                        src="/images/babymom.jpg"
                        alt="Female gynecologist"
                        className="w-full h-full object-cover"
                     />
                  </motion.section>
               </div>
            </ScatteredPurpleBackground>

            <section className="contact-info py-24 bg-gray-100">
               <motion.div className="container mx-auto px-4">
                  <h2 className="text-2xl md:text-4xl font-bold text-center mb-16 text-purple-800">Get in Touch with Dr. Kanika Jain</h2>
                  <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                     <ContactItem
                        icon={<FaMapMarkerAlt className="text-blue-500" />}
                        title="Address"
                        content="Sir Ganga Ram Hospital, New Delhi"
                        gradientClass="from-blue-400 to-blue-600"
                        action={scrollToMap}
                     />
                     <ContactItem
                        icon={<FaPhone className="text-green-500" />}
                        title="Phone"
                        content="+011-42251764, +011-42251765"
                        gradientClass="from-green-400 to-green-600"
                        action={() => window.location.href = 'tel:+01142251764'}
                     />
                     <ContactItem
                        icon={<FaEnvelope className="text-purple-500" />}
                        title="Email"
                        content="dr.kanika@gmail.com"
                        gradientClass="from-purple-400 to-purple-600"
                        action={() => window.location.href = 'mailto:dr.kanika@gmail.com'}
                     />
                     <ContactItem
                        icon={<FaClock className="text-red-500" />}
                        title="OPD Hours"
                        content="Mon, Wed, Fri: 4pm - 6pm | Tue, Thu, Sat: 12pm - 2pm"
                        gradientClass="from-red-400 to-red-600"
                        action={() => { }}
                     />
                  </motion.div>
               </motion.div>
            </section>



            <section className="services py-20 bg-gray-100">
               <motion.div className="container mx-auto px-4">
                  <h2 className="text-3xl font-bold text-center text-purple-600 mb-12">Call Us For</h2>
                  <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                     {[
                        "High Risk Pregnancy",
                        "Infertility Treatment",
                        "Laparoscopic Surgery",
                        "Gynae Endoscopy",
                        "PCOS Care",
                        "Menopause Management",
                        "Normal Delivery",
                        "Health Check-Up",
                        "Adolescent Health"
                     ].map((service, index) => (
                        <motion.div
                           key={index}
                           className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300"
                        >
                           <FaStethoscope className="text-purple-500 text-3xl mb-4" />
                           <h3 className="text-xl font-semibold mb-2">{service}</h3>
                        </motion.div>
                     ))}
                  </motion.div>
               </motion.div>
            </section>
            <section id="contact-form" className="contact-form bg-white py-20">
               <motion.div className="container mx-auto px-4">
                  <motion.div className="max-w-6xl mx-auto">
                     <motion.div className="flex flex-col lg:flex-row items-center">
                        {/* Image section */}
                        <motion.div className="w-full lg:w-1/2 lg:pl-8 mb-8 lg:mb-0 order-1 lg:order-2">
                           <img
                              src="/images/hero-contact.jpg"
                              alt="Doctor consultation"
                              className="rounded-lg shadow-lg w-full h-auto object-cover"
                           />
                        </motion.div>
                        {/* Form section */}
                        <motion.div className="w-full lg:w-1/2 lg:pr-8 order-2 lg:order-1">
                           {state.succeeded ? (
                              <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 lg:mb-8 text-center lg:text-left mt-4 sm:mt-6 lg:mt-0 px-4 lg:px-0">
                                 Your Form has been sent successfully!
                              </h3>
                           ) : (
                              <motion.div>
                                 <h3 className="text-2xl md:text-4xl text-purple-600 font-bold mb-8 text-center lg:text-left mt-8 lg:mt-0">Schedule Your Appointment</h3>

                                 <form onSubmit={handleFinalSubmit} className="space-y-6">
                                    <motion.div className="mb-8">
                                       <label htmlFor="name" className="block text-oxford-blue text-sm font-semibold mb-2">Name </label>
                                       <input
                                          type="text"
                                          name="name"
                                          value={formData.name}
                                          onChange={handleChange}
                                          id="name"
                                          required
                                          placeholder="Type Name"
                                          className="border-b border-platinum py-2 text-sm w-full"
                                       />
                                    </motion.div>

                                    <motion.div className="mb-8">
                                       <label htmlFor="phone" className="block text-oxford-blue text-sm font-semibold mb-2">Phone</label>
                                       <input
                                          type="tel"
                                          name="phone"
                                          id="phone"
                                          value={formData.phone}
                                          onChange={handleChange}
                                          placeholder="Type Phone Number"
                                          className="border-b border-platinum py-2 text-sm w-full"
                                       />
                                    </motion.div>

                                    <motion.div className="mb-8">
                                       <label htmlFor="email" className="block text-oxford-blue text-sm font-semibold mb-2">Email Address </label>
                                       <input
                                          type="email"
                                          name="email"
                                          id="email"
                                          value={formData.email}
                                          onChange={handleChange}
                                          required
                                          placeholder="Type Email Address"
                                          className="border-b border-platinum py-2 text-sm w-full"
                                       />
                                       <ValidationError prefix="Email" field="email" errors={state.errors} />
                                    </motion.div>
                                    <div>
                                       <label className="block text-sm font-medium text-purple-700 mb-2">Appointment Type</label>
                                       <div className="flex flex-wrap gap-4">
                                          <label className="inline-flex items-center">
                                             <input
                                                type="radio"
                                                name="appointmentType"
                                                value="offline"
                                                checked={formData.appointmentType === 'offline'}
                                                onChange={handleChange}
                                                className="form-radio text-purple-600 focus:ring-purple-500 h-4 w-4"
                                             />
                                             <span className="ml-2 text-purple-700">Offline Appointment</span>
                                          </label>
                                          <label className="inline-flex items-center">
                                             <input
                                                type="radio"
                                                name="appointmentType"
                                                value="online"
                                                checked={formData.appointmentType === 'online'}
                                                onChange={handleChange}
                                                className="form-radio text-purple-600 focus:ring-purple-500 h-4 w-4"
                                             />
                                             <span className="ml-2 text-purple-700">Online Appointment</span>
                                          </label>
                                       </div>
                                    </div>

                                    <motion.div className="mb-8">
                                       <label htmlFor="date" className="block text-oxford-blue text-sm font-semibold mb-2">Date</label>
                                       <input
                                          type="date"
                                          name="date"
                                          id="date"
                                          value={formData.date}
                                          onChange={handleChange}
                                          required
                                          placeholder="Select Date"
                                          className="border-b border-platinum py-2 text-sm w-full"
                                       />
                                       <ValidationError prefix="Date" field="date" errors={state.errors} />
                                    </motion.div>



                                    <motion.div className="mb-8">
                                       <label htmlFor="message" className="block text-oxford-blue text-sm font-semibold mb-2">How can I help? </label>
                                       <textarea
                                          name="message"
                                          id="message"
                                          value={formData.message}
                                          onChange={handleChange}
                                          placeholder="Type Description"
                                          required
                                          className="border-b border-platinum py-2 text-sm w-full max-h-72 min-h-24 h-24 resize-vertical"
                                       ></textarea>
                                    </motion.div>

                                    <button
                                       type="submit"
                                       className="w-full bg-purple-600 text-white font-semibold py-3 px-6 rounded-md hover:bg-purple-700 transition duration-300 ease-in-out transform hover:scale-105"
                                    >
                                       Schedule Appointment
                                    </button>
                                 </form>
                              </motion.div>)}
                        </motion.div>


                     </motion.div>
                  </motion.div>
               </motion.div>
            </section>

            <section ref={mapRef} className="map-section py-20">
               <motion.div className="container mx-auto px-4">
                  <h2 className="text-3xl font-bold mb-8 text-center text-purple-600">Our Location</h2>
                  <motion.div className="h-96 rounded-lg overflow-hidden shadow-lg">
                     <MapContainer center={position} zoom={15} style={{ height: '400px', width: '100%' }}>
                        <TileLayer
                           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={position} icon={customIcon}>
                           <Popup>
                              Dr. Kanika Jain
                           </Popup>
                        </Marker>
                     </MapContainer>
                  </motion.div>
               </motion.div>
            </section>

         </main>
      </>
   );
};

interface ContactCardProps {
   icon: React.ReactNode;
   title: string;
   content: string;
   gradientClass: string;
   action: () => void;
}

const ContactItem: React.FC<ContactCardProps> = ({ icon, title, content, gradientClass, action }) => (
   <motion.div
      className={`rounded-lg shadow-lg p-8 text-center hover:shadow-xl transition duration-300 bg-gradient-to-br ${gradientClass} relative overflow-hidden group cursor-pointer`}
      onClick={action}
   >
      <motion.div className="absolute inset-0 bg-white opacity-90 group-hover:opacity-95 transition-opacity duration-300"></motion.div>
      <motion.div className="relative z-10 flex flex-col items-center justify-center h-full">
         <motion.div className="text-5xl mb-6 transform group-hover:scale-110 transition-transform duration-300">
            {icon}
         </motion.div>
         <h3 className="text-2xl font-bold mb-3 text-gray-800">{title}</h3>
         <p className="text-gray-600 text-lg leading-relaxed max-w-xs mx-auto">{content}</p>
      </motion.div>
   </motion.div>

);

export default Contact;