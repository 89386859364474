import React from 'react';
import { Helmet } from 'react-helmet';

type PageType = 'home' | 'about' | 'services' | 'blogs' | 'contact';

interface SEOMetaTagsProps {
  page: PageType;
}

const SEOMetaTags: React.FC<SEOMetaTagsProps> = ({ page }) => {
  const baseTitle = "Dr. Kanika Jain - Top Gynecologist & Robotic Surgeon in Delhi NCR";
  const baseDescription = "Dr. Kanika Jain: Leading gynecologist and robotic surgeon in Delhi NCR with 25+ years of experience. Expert in high-risk pregnancies, laparoscopic surgeries, and infertility treatments.";
  const baseKeywords = "Dr. Kanika Jain, gynecologist Delhi NCR, robotic surgeon, laparoscopic surgeon, infertility specialist, high-risk pregnancy, PCOS, endometriosis, Sir Ganga Ram Hospital";

  const pageTitles: Record<PageType, string> = {
    home: baseTitle,
    about: `About ${baseTitle} - Experience & Expertise`,
    services: `Services Offered by ${baseTitle} - Comprehensive Women's Healthcare`,
    blogs : `Blogs by ${baseTitle} - Insights & Research`, 
    contact: `Contact ${baseTitle} - Book Your Consultation Today`
  };

  const pageDescriptions: Record<PageType, string> = {
    home: baseDescription,
    about: `Learn about Dr. Kanika Jain's 25+ years of experience, qualifications, and achievements as a top gynecologist and robotic surgeon in Delhi NCR. Trusted expert at Sir Ganga Ram Hospital.`,
    services: `Comprehensive women's healthcare services by Dr. Kanika Jain in Delhi NCR. Specializing in high-risk pregnancies, infertility treatments, laparoscopic and robotic surgeries, PCOS, and endometriosis.`,
    blogs: `Blogs written by Dr. Kanika Jain`,
    contact: `Book a consultation with Dr. Kanika Jain, leading gynecologist and robotic surgeon in Delhi NCR. Get expert care for all your women's health concerns at Sir Ganga Ram Hospital.`
  };

  return (
    <Helmet>
      <title>{pageTitles[page]}</title>
      <meta name="description" content={pageDescriptions[page]} />
      <meta name="keywords" content={baseKeywords} />
      <meta name="author" content="Dr. Kanika Jain" />
      <meta property="og:title" content={pageTitles[page]} />
      <meta property="og:description" content={pageDescriptions[page]} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://www.drkanikajain.com/${page}`} />
      <meta property="og:image" content="https://www.drkanikajain.com/dr-kanika-jain-profile.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitles[page]} />
      <meta name="twitter:description" content={pageDescriptions[page]} />
      <meta name="twitter:image" content="https://www.drkanikajain.com/dr-kanika-jain-profile.jpg" />
      <link rel="canonical" href={`https://www.drkanikajain.com/${page}`} />
    </Helmet>
  );
};

export default SEOMetaTags;