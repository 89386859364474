import React, { useEffect, useState } from 'react';
import ScatteredPurpleBackground from '../ScatteredPurpleBackground';
import { motion } from 'framer-motion';
import { AnimatedWord } from './Home';
import AppointmentModal from '../components/utility/AppointmentModal';
import { ArrowDownCircle, Mail } from 'lucide-react';
import SEOMetaTags from '../components/utility/SEOMetaTags';
import OnlineModal from '../components/utility/OnlineAppointment';

const AboutPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEOMetaTags page='about' />
      <ScatteredPurpleBackground>
        <div className="flex flex-col md:flex-row min-h-screen relative">
          {/* Background Image (visible on mobile) */}
          <div className="absolute inset-0 md:hidden">
            <div
              className="w-full h-full bg-cover bg-center"
              style={{
                backgroundImage: 'url(/images/kanikajain-2.jpg)',
                filter: "brightness(0.7)"
              }}
            />
          </div>

          <motion.section
            className="w-full md:w-1/2 flex items-center justify-center px-6 py-12 mt-20 md:mt-0 md:py-24 relative z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
          >
            <div className="max-w-xl w-full">
              <h1 className="font-fraunces-slab text-3xl md:text-4xl lg:text-6xl leading-tight mb-6 text-white md:text-zinc-900 ml-5 lg:ml-48">
                <span className="block md:inline">Dr. Kanika Jain</span>

                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block md:inline"
                >
                  {' '}
                  <AnimatedWord word="Expert" />
                </motion.span>
                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block md:inline"
                >
                  <AnimatedWord word="Gynecologist" />
                </motion.span>
                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block md:inline"
                >
                  <AnimatedWord word="&" />
                </motion.span>
                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block md:inline"
                >
                  <AnimatedWord word="Laparoscopic" />
                </motion.span>
                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block md:inline"
                >
                  <AnimatedWord word="Surgeon" />
                </motion.span>
              </h1>

              <motion.div
                className="mt-8 flex flex-wrap mt-20 items-center space-x-2 md:space-x-4 ml-5 lg:ml-48"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              >
                <button
                  className="inline-flex items-center px-4 py-2 md:px-6 md:py-3 text-md md:text-xl lg:text-xl font-semibold text-white md:text-black bg-purple-600 md:bg-transparent rounded-lg mb-2 md:mb-0"
                  onClick={openModal}
                >
                  Get Consultation
                  <ArrowDownCircle className="ml-2 w-4 h-4 md:w-6 md:h-6" />
                </button>
                <a href="mailto:dr.kanika@gmail.com" className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-purple-600 rounded-full mb-2 md:mb-0">
                  <Mail className="w-5 h-5 md:w-6 md:h-6 text-white" />
                </a>
                <a href="#" onClick={openModal2} className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-green-500 rounded-full mb-2 md:mb-0">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 md:w-7 md:h-7 fill-current text-white">
                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.890-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                  </svg>
                </a>
                <OnlineModal isOpen2={isModalOpen2} onClose2={closeModal2} />
              </motion.div>
            </div>
          </motion.section>

          {/* Image Section (visible on desktop) */}
          <motion.section
            className="hidden md:block w-1/2 h-screen relative"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.9, delay: 0.3 }}
          >
            <img
              src="/images/kanikajain-2.jpg"
              alt="Dr. Kanika Jain"
              className="w-full h-full object-cover"
            />
          </motion.section>
        </div>

        <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
      </ScatteredPurpleBackground>

      <div className="mt-12 md:mt-24 bg-gray-100">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-2/3 pr-4">
                <h2 className="text-3xl font-semibold mb-6 text-purple-800">About Dr. Kanika Jain</h2>
                <p className="mb-6 text-lg font-playfair-display-real leading-relaxed">
                  Dr. Kanika Jain is a renowned Obstetrician, Gynecologist, Infertility Specialist & Laparoscopic Surgeon in New Delhi. With over 28 years of experience, she has been associated with some of the best hospitals in New Delhi. A dedicated and compassionate doctor, she handles many challenging cases with the latest cutting-edge technology, offering patient-friendly scientific advice while maintaining the highest professional and ethical values.
                </p>
                <div className="block lg:hidden mb-6">
                  <img
                    src="/images/drkj.jpeg"
                    alt="Dr. Kanika Jain"
                    className="w-full rounded-lg shadow-lg"
                  />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-purple-700">Education & Qualifications</h3>
                <ul className="list-disc pl-5 mb-6 text-lg font-playfair-display-real">
                  <li>MBBS</li>
                  <li>DGO</li>
                  <li>DNB</li>
                  <li>FICOG</li>
                  <li>FICMCH</li>
                </ul>
                <h3 className="text-2xl font-semibold mb-4 text-purple-700">Achievements & Honors</h3>
                <ul className="list-disc pl-5 mb-6 text-lg font-playfair-display-real">
                  <li>Stood 2nd in Delhi University in Post Graduation</li>
                  <li>Awarded APJ Abdul Kalam award for proficiency in gynae</li>
                  <li>Recipient of many prizes at National, International, and state levels for best paper and poster presentations</li>
                  <li>Delivered lectures at National and State levels</li>
                  <li>Fellowship in endoscopic surgery 2008</li>
                  <li>Fellowship in gynae Robotic surgery 2012</li>
                  <li>Certified robotic surgeon from EEC Paris 2014</li>
                </ul>
                <h3 className="text-2xl font-semibold mb-4 text-purple-700">Professional Positions</h3>
                <ul className="list-disc pl-5 mb-6 text-lg font-playfair-display-real">
                  <li>Senior Consultant Gynae Endoscopic and Robotic Surgeon, Sir Ganga Ram Hospital</li>
                  <li>Chairperson AOGD Endoscopy Committee 2021-23</li>
                  <li>Vice President AOGD 2020-21</li>
                  <li>Executive Member Endoscopy Committee FOGSI</li>
                  <li>Executive member IAGE Delhi</li>
                  <li>Secretary NARCHI Delhi</li>
                </ul>
              </div>
              <div className="lg:w-1/3 mt-6 lg:mt-0">
                <img
                  src="/images/drkj.jpeg"
                  alt="Dr. Kanika Jain"
                  className="w-full hidden lg:block rounded-lg shadow-lg mb-6"
                />
                <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
                  <h3 className="text-2xl font-semibold mb-4 text-purple-700">Specializations</h3>
                  <ul className="list-disc pl-5 text-lg font-playfair-display-real">
                    <li>Gynae Endoscopy</li>
                    <li>Gynae Oncology</li>
                    <li>Infertility Treatment</li>
                    <li>High Risk Pregnancy</li>
                    <li>Laparoscopic Surgery</li>
                    <li>Robotic Surgery</li>
                  </ul>
                </div>
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h3 className="text-2xl font-semibold mb-4 text-purple-700">Services</h3>
                  <ul className="list-disc pl-5 text-lg font-playfair-display-real">
                    <li>High Risk Pregnancy</li>
                    <li>Infertility Treatment</li>
                    <li>Menopause Management</li>
                    <li>Endometriosis Treatment</li>
                    <li>Fibroid Treatments</li>
                    <li>PCOS Care</li>
                    <li>Laparoscopic Surgery</li>
                    <li>Normal Delivery</li>
                    <li>Health Check-Up</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;