export interface ServiceData {
    image: string;
    title: string;
    description: string;
    symptoms: string[];
    causes: string[];
    treatments: string[];
    estimatedDuration: string;
    followUpCare: string;
    slug: string;
  }
  
  const servicesData: Record<string, ServiceData> = {
    highriskpregnancy: {
      image: "/images/high-risk-pregnancy.jpg",
      title: "High Risk Pregnancy",
      description: "Specialized care for pregnancies with increased health risks for mother or baby.",
      symptoms: [
        "Preexisting medical conditions",
        "Pregnancy-induced hypertension",
        "Gestational diabetes",
        "Multiple gestation",
        "Advanced maternal age"
      ],
      causes: [
        "Chronic health conditions",
        "Previous pregnancy complications",
        "Genetic factors",
        "Lifestyle factors",
        "Uterine abnormalities"
      ],
      treatments: [
        "Frequent prenatal check-ups",
        "Specialized monitoring",
        "Medication management",
        "Dietary and lifestyle modifications",
        "Early intervention strategies"
      ],
      estimatedDuration: "Throughout pregnancy and immediate postpartum period",
      followUpCare: "Regular postpartum check-ups and ongoing health monitoring",
      slug:"highriskpregnancy"
    },
    stds: {
      image: "/images/stds.jpg",
      title: "Sexually Transmitted Diseases (STDs)",
      description: "Diagnosis, treatment, and management of sexually transmitted infections.",
      symptoms: [
        "Unusual discharge",
        "Genital sores or rashes",
        "Painful urination",
        "Pelvic pain",
        "Fever or flu-like symptoms"
      ],
      causes: [
        "Bacterial infections",
        "Viral infections",
        "Parasitic infections",
        "Unprotected sexual contact",
        "Mother-to-child transmission"
      ],
      treatments: [
        "Antibiotic therapy",
        "Antiviral medications",
        "Topical treatments",
        "Partner notification and treatment",
        "Counseling and education"
      ],
      estimatedDuration: "Varies based on the specific STD and treatment plan",
      followUpCare: "Regular testing and check-ups to ensure successful treatment",
      slug:"stds"
    },
    infertility: {
      image: "/images/infertility.jpg",
      title: "Infertility",
      description: "Comprehensive evaluation and treatment for couples struggling to conceive.",
      symptoms: [
        "Inability to conceive after 1 year of trying",
        "Irregular menstrual cycles",
        "Hormonal imbalances",
        "Recurrent miscarriages",
        "Male factor infertility"
      ],
      causes: [
        "Ovulation disorders",
        "Uterine or cervical abnormalities",
        "Fallopian tube damage",
        "Endometriosis",
        "Age-related factors"
      ],
      treatments: [
        "Fertility medications",
        "Intrauterine insemination (IUI)",
        "In vitro fertilization (IVF)",
        "Surgery for structural issues",
        "Lifestyle modifications"
      ],
      estimatedDuration: "Varies; can range from months to years",
      followUpCare: "Ongoing monitoring and support throughout treatment",
      slug:"infertility"
    },
    menopause: {
      image: "/images/menopause.jpg",
      title: "Menopause",
      description: "Management of menopausal symptoms and health concerns.",
      symptoms: [
        "Hot flashes and night sweats",
        "Vaginal dryness",
        "Mood changes",
        "Sleep disturbances",
        "Changes in libido"
      ],
      causes: [
        "Natural aging process",
        "Surgical removal of ovaries",
        "Chemotherapy or radiation therapy",
        "Premature ovarian failure",
        "Genetic factors"
      ],
      treatments: [
        "Hormone replacement therapy",
        "Non-hormonal medications",
        "Lifestyle modifications",
        "Vaginal estrogen treatments",
        "Counseling and support"
      ],
      estimatedDuration: "Transition period typically lasts 4-8 years",
      followUpCare: "Regular check-ups and screenings for associated health risks",
      slug:"menopause"
    },
    endometriosis: {
      image: "/images/endometriosis.jpg",
      title: "Endometriosis",
      description: "Diagnosis and treatment of endometrial tissue growth outside the uterus.",
      symptoms: [
        "Severe menstrual cramps",
        "Chronic pelvic pain",
        "Pain during intercourse",
        "Heavy menstrual bleeding",
        "Infertility"
      ],
      causes: [
        "Retrograde menstruation",
        "Cellular metaplasia",
        "Genetic factors",
        "Immune system dysfunction",
        "Hormonal imbalances"
      ],
      treatments: [
        "Pain medications",
        "Hormonal therapies",
        "Laparoscopic surgery",
        "Fertility treatments",
        "Lifestyle modifications"
      ],
      estimatedDuration: "Chronic condition requiring ongoing management",
      followUpCare: "Regular monitoring and adjustment of treatment plan",
      slug:"endometriosis"
    },
    fibroidTreatments: {
      image: "/images/fibroid-treatments.jpg",
      title: "Fibroid Treatments",
      description: "Management and removal of uterine fibroids using various techniques.",
      symptoms: [
        "Heavy menstrual bleeding",
        "Pelvic pressure or pain",
        "Frequent urination",
        "Constipation",
        "Backache or leg pains"
      ],
      causes: [
        "Genetic factors",
        "Hormonal imbalances",
        "Environmental factors",
        "Obesity",
        "Vitamin D deficiency"
      ],
      treatments: [
        "Medications for symptom relief",
        "Uterine fibroid embolization",
        "Myomectomy",
        "Hysterectomy",
        "MRI-guided focused ultrasound"
      ],
      estimatedDuration: "Depends on treatment method; can be immediate to several months",
      followUpCare: "Post-treatment monitoring and symptom management",
      slug:"fibroidTreatments"
    },
    pcosCare: {
      image: "/images/pcos.jpg",
      title: "PCOS Care",
      description: "Comprehensive management of Polycystic Ovary Syndrome symptoms and complications.",
      symptoms: [
        "Irregular periods",
        "Excess androgen",
        "Polycystic ovaries",
        "Weight gain",
        "Acne or hirsutism"
      ],
      causes: [
        "Insulin resistance",
        "Hormonal imbalances",
        "Genetic factors",
        "Inflammation",
        "Environmental factors"
      ],
      treatments: [
        "Lifestyle modifications",
        "Hormonal birth control",
        "Metformin",
        "Anti-androgen medications",
        "Fertility treatments"
      ],
      estimatedDuration: "Ongoing management throughout reproductive years",
      followUpCare: "Regular monitoring of symptoms and metabolic health",
      slug:"pcosCare"
    },
    iui: {
      image: "/images/iui.png",
      title: "Intrauterine Insemination (IUI)",
      description: "Fertility treatment involving direct sperm placement into the uterus.",
      symptoms: [
        "Difficulty conceiving naturally",
        "Unexplained infertility",
        "Mild male factor infertility",
        "Cervical factor infertility",
        "Ovulatory disorders"
      ],
      causes: [
        "Low sperm count or motility",
        "Cervical mucus problems",
        "Ovulation timing issues",
        "Mild endometriosis",
        "Unexplained infertility"
      ],
      treatments: [
        "Ovulation induction medications",
        "Sperm washing and preparation",
        "Insemination procedure",
        "Luteal phase support",
        "Pregnancy testing and follow-up"
      ],
      estimatedDuration: "2-3 weeks per cycle, often multiple cycles",
      followUpCare: "Pregnancy monitoring or planning for next steps",
      slug:"iui"
    },
    adolescentHealth: {
      image: "/images/adolescent-health.jpg",
      title: "Adolescent Health",
      description: "Specialized gynecological care for teenagers and young women.",
      symptoms: [
        "Irregular periods",
        "Painful menstruation",
        "Acne",
        "Mood swings",
        "Concerns about sexual health"
      ],
      causes: [
        "Hormonal changes",
        "Genetic factors",
        "Lifestyle factors",
        "Stress",
        "Underlying medical conditions"
      ],
      treatments: [
        "Menstrual cycle regulation",
        "Acne treatments",
        "Sexual health education",
        "Vaccinations (e.g., HPV)",
        "Mental health support"
      ],
      estimatedDuration: "Ongoing care throughout adolescence",
      followUpCare: "Regular check-ups and health education",
      slug:"adolescentHealth"
    },
    normalDelivery: {
      image: "/images/normal-delivery.jpg",
      title: "Normal Delivery",
      description: "Support and care for natural childbirth process.",
      symptoms: [
        "Regular contractions",
        "Water breaking",
        "Bloody show",
        "Nesting instinct",
        "Lightening or baby dropping"
      ],
      causes: [
        "Full-term pregnancy",
        "Hormonal changes",
        "Fetal positioning",
        "Cervical ripening",
        "Natural onset of labor"
      ],
      treatments: [
        "Labor monitoring",
        "Pain management techniques",
        "Assisted pushing techniques",
        "Perineal support",
        "Immediate postpartum care"
      ],
      estimatedDuration: "Active labor typically lasts 8-12 hours for first-time mothers",
      followUpCare: "Postpartum check-ups and newborn care",
      slug:"normalDelivery"
    },
    healthCheckUp: {
      image: "/images/health-checkup.jpg",
      title: "Health Check-Up",
      description: "Comprehensive gynecological health screening and preventive care.",
      symptoms: [
        "Routine screening",
        "Preventive care",
        "Health concerns assessment",
        "Family history evaluation",
        "Lifestyle risk factors"
      ],
      causes: [
        "Age-related health risks",
        "Genetic predispositions",
        "Lifestyle factors",
        "Environmental exposures",
        "Previous medical history"
      ],
      treatments: [
        "Pap smear",
        "Breast examination",
        "STD testing",
        "Hormonal level assessment",
        "General health screening"
      ],
      estimatedDuration: "1-2 hours for comprehensive check-up",
      followUpCare: "Annual or as recommended based on findings",
      slug: "healthCheckUp"
    },
    laparoscopy: {
      image: "/images/laparoscopy.jpg",
      title: "Laparoscopy",
      description: "Minimally invasive surgical technique for diagnosis and treatment of gynecological issues.",
      symptoms: [
        "Pelvic pain",
        "Infertility",
        "Suspicious masses",
        "Abnormal bleeding",
        "Ectopic pregnancy"
      ],
      causes: [
        "Endometriosis",
        "Ovarian cysts",
        "Fibroids",
        "Pelvic inflammatory disease",
        "Adhesions"
      ],
      treatments: [
        "Diagnostic visualization",
        "Biopsy collection",
        "Removal of cysts or fibroids",
        "Treatment of endometriosis",
        "Tubal ligation"
      ],
      estimatedDuration: "30 minutes to several hours, depending on procedure",
      followUpCare: "Post-operative check-ups and symptom monitoring",
      slug: "laparoscopy"

    },
    vaccinationAndCheckup: {
      image: "/images/vaccination.jpg",
      title: "Vaccination And Check-up",
      description: "Preventive care including vaccinations and routine health screenings.",
      symptoms: [
        "Routine preventive care",
        "Age-specific health concerns",
        "Travel-related health needs",
        "Occupational health requirements",
        "Family planning considerations"
      ],
      causes: [
        "Age-related health risks",
        "Lifestyle factors",
        "Environmental exposures",
        "Genetic predispositions",
        "Public health recommendations"
      ],
      treatments: [
        "HPV vaccination",
        "Influenza vaccination",
        "Tetanus-diphtheria booster",
        "Hepatitis B vaccination",
        "Routine health screenings"
      ],
      estimatedDuration: "1-2 hours for comprehensive check-up and vaccinations",
      followUpCare: "As recommended based on vaccination schedule and health status",
      slug: "vaccinationAndCheckup"
    }
  };
  
  export default servicesData;