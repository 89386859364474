import React, { useState } from "react";
import { Link } from 'react-router-dom';
import ScatteredPurpleBackground from "../ScatteredPurpleBackground";
import { AnimatedWord } from "./Home";
import { motion } from 'framer-motion';
import AppointmentModal from "../components/utility/AppointmentModal";
import servicesData, { ServiceData } from "../components/utility/services";
import { ArrowDownCircle, Mail } from 'lucide-react';
import SEOMetaTags from "../components/utility/SEOMetaTags";
import OnlineModal from "../components/utility/OnlineAppointment";



export const ServiceCard: React.FC<{ service: ServiceData; path?: string }> = ({ service, path }) => (
    <motion.div
        className="bg-white rounded-lg shadow-md overflow-hidden"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
    >
        <Link to={path || `/services/${service.slug}`}>
            <img src={service.image} alt={service.title} className="w-full h-48 object-cover" />
            <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
            </div>
        </Link>
    </motion.div>
);

const Services: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const openModal2 = () => setIsModalOpen2(true);
    const closeModal2 = () => setIsModalOpen2(false);


    return (
        <>
        <SEOMetaTags page='services' />
        <main className="flex-grow">
            <ScatteredPurpleBackground>
                <div className="flex flex-col md:flex-row min-h-screen relative">
                    {/* Background Image (visible on mobile) */}
                    <div className="absolute inset-0 md:hidden">
                        <div
                            className="w-full h-full bg-cover bg-center"
                            style={{
                                backgroundImage: "url('/images/services-hero.jpg')",
                                filter: "brightness(0.7)"
                            }}
                        />
                    </div>
                    {/* Text Section */}
                    <motion.section
                        className="w-full md:w-1/2 flex items-center justify-center px-6 py-12 mt-20 md:mt-0 md:py-24 relative z-10"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.6 }}
                    >
                        <div className="max-w-xl">
                            <h1 className="font-fraunces-slab text-4xl md:text-6xl lg:text-7xl leading-tight mb-6 text-white md:text-zinc-900 ml-5 lg:ml-48">
                                Expertise in
                                <br/>
                                <motion.span
                                    initial="hidden"
                                    animate="visible"
                                    variants={{
                                        hidden: {},
                                        visible: {
                                            transition: {
                                                staggerChildren: 0.8
                                            }
                                        }
                                    }}
                                >
                                    <AnimatedWord word="Gynae" />
                                    {' '}
                                    <AnimatedWord word="Endoscopy," />
                                    {' '}
                                    <br/>
                                    <AnimatedWord word="Gynae" />
                                    {' '}
                                    <AnimatedWord word="Oncology" />
                                </motion.span>
                            </h1>

                            <motion.div
                                className="mt-8 flex flex-wrap mt-20 items-center space-x-2 md:space-x-4 ml-5 lg:ml-48"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.3 }}
                            >
                                <button
                                    className="inline-flex items-center px-4 py-2 md:px-6 md:py-3 text-md md:text-xl lg:text-xl font-semibold text-white md:text-black bg-purple-600 md:bg-transparent rounded-lg mb-2 md:mb-0"
                                    onClick={openModal}
                                >
                                    Get Consultation
                                    <ArrowDownCircle className="ml-2 w-4 h-4 md:w-6 md:h-6" />
                                </button>
                                <a href="mailto:dr.kanika@gmail.com" className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-purple-600 rounded-full mb-2 md:mb-0">
                                    <Mail className="w-5 h-5 md:w-6 md:h-6 text-white" />
                                </a>
                                <a onClick={openModal2} href="#" className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-green-500 rounded-full mb-2 md:mb-0">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 md:w-7 md:h-7 fill-current text-white">
                                 <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.890-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                              </svg>
                           </a>
                           <OnlineModal isOpen2={isModalOpen2} onClose2={closeModal2} />
                            </motion.div>
                            <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
                        </div>
                    </motion.section>

                    {/* Image Section (visible on desktop) */}
                    <motion.section
                        className="hidden md:block w-1/2 h-screen relative"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.9, delay: 0.3 }}
                    >
                        <img
                            src="/images/services-hero.jpg"
                            alt="Female gynecologist"
                            className="w-full h-full object-cover"
                        />
                    </motion.section>
                </div>
            </ScatteredPurpleBackground>

            <motion.section id="service-cards" className="container mx-auto px-4 py-12 mt-2">
                <h1 className="text-5xl lg:text-5xl font-bold mb-6 text-center text-accent">Services</h1>
                <p className="text-center mb-4 text-lg font-bold text-accent">Offered by Dr. Kanika Jain</p>

                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {Object.values(servicesData).map((service, index) => (
                        <ServiceCard key={index} service={service} />
                    ))}
                </div>
            </motion.section>
        </main>
    </>
    );
};

export default Services;