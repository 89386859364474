import React, { useState } from "react";
import { motion, useInView } from "framer-motion";
import AppointmentModal from "../components/utility/AppointmentModal";
import ScatteredPurpleBackground from "../ScatteredPurpleBackground";
import { ArrowRight, Star, Award } from "react-feather";
import { useNavigate } from "react-router-dom";
import { ArrowDownCircle, Mail } from 'lucide-react';
import { useForm, ValidationError } from '@formspree/react';
import TestimonialCarousel from "../components/utility/TestimonialCarousel";
import ServicesPreview from "../components/utility/ServicesPreview";
import SEOMetaTags from "../components/utility/SEOMetaTags";
import ResponsiveImageCarousel from "../components/utility/ResponsiveImageCarousel";
import BlogPreview from "../components/utility/BlogPreview";
import OnlineModal from "../components/utility/OnlineAppointment";


const AnimatedLetter = ({ letter }: { letter: string }) => {
  return (
    <motion.span
      className="inline-block"
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
      }}
      transition={{ duration: 0.5 }}
    >
      {letter}
    </motion.span>
  )
}

export const AnimatedWord = ({ word }: { word: string }) => {
  return (
    <motion.span
      className="inline-block"
      variants={{
        hidden: {},
        visible: {
          transition: {
            staggerChildren: 0.05
          }
        }
      }}
    >
      {word.split('').map((letter, index) => (
        <AnimatedLetter key={index} letter={letter} />
      ))}
    </motion.span>
  );
};

export const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

export const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100
    }
  }
};


const Home: React.FC = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);

  const [state, handleSubmit] = useForm("xovqqvee");
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    appointmentType: 'offline',
    date: '',
    message: '',
  });

  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });

  const navigate = useNavigate();

  const scrollToIntro = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const doctorIntro = document.getElementById('doctor-intro');
    if (doctorIntro) {
      doctorIntro.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFinalSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(formData);
    sendWhatsAppMessage();
  };

  const sendWhatsAppMessage = () => {
    const message = `New Appointment Request:
    Name: ${formData.name}
    Phone: ${formData.phone}
    Email: ${formData.email}
    Appointment : ${formData.appointmentType}
    Date: ${formData.date}
    Message: ${formData.message}`;

    const whatsappNumber = '919811022255';
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  }

  const carouselItems = [
    { src: "/images/srgh-logo.jpeg", alt: "Sir Ganga Ram Hospital", title: "SRGH" },
    { src: "/images/gripmer.png", alt: "Gripmer", title: "GRIPMER" },
    {src: '/images/aogd.jpg', alt: "AOGD", title: "AOGD"}, 
    { src: "/images/iage-logo.png", alt: "IAGE", title: "IAGE, Delhi" },
    { src: "/images/ifs-logo.jpg", alt: "IFS", title: "IFS" },
    { src: "/images/isoparb.jpg", alt: "ISOPARB", title: "ISOPARB" },
    { src: "/images/narchi-logo.png", alt: "Narchi", title: "NARCHI, Delhi" }
  ];

  return (
    <>
      <SEOMetaTags page='home' />
      <main className="flex-grow">

        <ScatteredPurpleBackground>
          <div className="flex flex-col md:flex-row min-h-screen relative">
            {/* Background Image (visible on mobile) */}
            <div className="absolute inset-0 md:hidden">
              <div
                className="w-full h-full bg-cover bg-center"
                style={{
                  backgroundImage: "url('/images/baby-nurse.jpg')",
                  filter: "brightness(0.7)"
                }}
              />
            </div>

            <motion.section
              className="w-full md:w-1/2 flex items-center justify-center px-6 py-12 mt-20 md:mt-0 md:py-24 relative z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              <div className="max-w-xl w-full">
                <h1 className="font-fraunces-slab text-3xl md:text-6xl lg:text-7xl leading-tight mb-6 text-white md:text-zinc-900 ml-5 lg:ml-48">
                  <span className="block md:inline">Empowering Your Health:</span>
                  <span className="block">From </span>
                  <motion.span
                    initial="hidden"
                    animate="visible"
                    variants={{
                      hidden: {},
                      visible: {
                        transition: {
                          staggerChildren: 0.8
                        }
                      }
                    }}
                    className="block md:inline"
                  >
                    <AnimatedWord word="Motherhood" />
                  </motion.span>
                  <motion.span
                    initial="hidden"
                    animate="visible"
                    variants={{
                      hidden: {},
                      visible: {
                        transition: {
                          staggerChildren: 0.8
                        }
                      }
                    }}
                    className="block md:inline"
                  >
                    <AnimatedWord word="to" />
                  </motion.span>
                  <motion.span
                    initial="hidden"
                    animate="visible"
                    variants={{
                      hidden: {},
                      visible: {
                        transition: {
                          staggerChildren: 0.8
                        }
                      }
                    }}
                    className="block md:inline"
                  >
                    {' '}
                    <AnimatedWord word="Wellness" />
                  </motion.span>
                </h1>

                <motion.div
                  className="mt-8 flex flex-wrap mt-20 items-center space-x-2 md:space-x-4 ml-5 lg:ml-48"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                >
                  <button
                    className="inline-flex items-center px-4 py-2 md:px-6 md:py-3 text-md md:text-xl lg:text-xl font-semibold text-white md:text-black bg-purple-600 md:bg-transparent rounded-lg mb-2 md:mb-0"
                    onClick={openModal}
                  >
                    Get Consultation
                    <ArrowDownCircle className="ml-2 w-4 h-4 md:w-6 md:h-6" />
                  </button>
                  <a href="mailto:dr.kanika@gmail.com" className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-purple-600 rounded-full mb-2 md:mb-0">
                    <Mail className="w-5 h-5 md:w-6 md:h-6 text-white" />
                  </a>
                  <a href="#" onClick={openModal2} className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-green-500 rounded-full mb-2 md:mb-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 md:w-7 md:h-7 fill-current text-white">
                      <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.890-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                    </svg>
                  </a>
                  <OnlineModal isOpen2={isModalOpen2} onClose2={closeModal2} />
                </motion.div>
              </div>
            </motion.section>




            {/* Image Section (visible on desktop) */}
            <motion.section
              className="hidden md:block w-1/2 h-screen relative"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.9, delay: 0.3 }}
            >
              <img
                src="/images/baby-nurse.jpg"
                alt="Female gynecologist"
                className="w-full h-full object-cover"
              />
            </motion.section>

            <motion.div
              className="absolute bottom-8 left-40 md:right-1/2 transform -translate-x-1/2 cursor-pointer"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1, repeat: Infinity, repeatType: "reverse" }}
              onClick={scrollToIntro}
            >
              <ArrowDownCircle className="w-16 h-16 text-gray-100" />
            </motion.div>
          </div>

          <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
        </ScatteredPurpleBackground>


        <motion.section
          id="doctor-intro"
          className="bg-[#faf9f5] py-12 md:py-24 overflow-hidden"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          ref={ref}
          variants={containerVariants}
        >

          <div className="container mx-auto px-4 mb-12">
            <motion.h1
              className="text-3xl md:text-5xl font-bold mb-8 text-center text-purple-900 font-fraunces-slab"
              variants={itemVariants}
            >
              Meet Your Expert
            </motion.h1>
            <div className="flex flex-col md:flex-row items-center">
              <motion.div
                className="w-full md:w-1/3 mb-8 md:mb-0 flex justify-center md:justify-start"
                variants={itemVariants}
              >
                <div className="border-4 border-[#FFDAB9] rounded-lg overflow-hidden shadow-lg max-w-xs">
                  <img
                    src="/images/kanika-jain.png"
                    alt="Dr. Kanika Jain"
                    className="w-full h-auto"
                  />
                </div>
              </motion.div>
              <motion.div
                className="w-full md:w-2/3 md:pl-12 text-center md:text-left"
                variants={itemVariants}
              >
                <h2 className="text-3xl font-bold mb-4 text-[#4B0082]">Dr. Kanika Jain</h2>
                <h3 className="text-sm md:text-lg text-gray-600 mb-4">Obstetrician, Gynecologist, Infertility Specialist & Laparoscopic Surgeon</h3>

                <p className="text-lg italic mb-6 text-black">
                  "In the intricate tapestry of women's health, each thread represents a unique journey. As a gynecologist, my role is to weave together cutting-edge medical expertise with deep empathy, creating a fabric of care that supports and empowers every woman at every stage of life."
                </p>
                <p className="mb-6 text-[#1E1E1E]">
                  Dr. Kanika Jain is a Senior Consultant Gynaecological Endoscopic & Robotic Surgeon at Sir Ganga Ram Hospital, New Delhi. With over 28 years of experience, she specializes in complex Gynae Endoscopic Surgeries and is passionate about minimal access surgeries in gynecology. An alumna of Lady Hardinge Medical College, Dr. Jain has held prestigious positions in various medical associations and is dedicated to teaching and advancing the field of gynecology.
                </p>
                <div className="flex justify-center md:justify-start">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-purple-600 text-white px-6 py-4 rounded-lg font-semibold flex items-center hover:bg-yellow-700 transition-colors"
                    onClick={() => navigate("/about")}
                  >
                    View Full Profile
                    <ArrowRight className="ml-2" size={20} />
                  </motion.button>
                </div>
              </motion.div>
            </div>
          </div>

          <ResponsiveImageCarousel items={carouselItems} />

          {/* Animated info strip outside container */}

          <motion.div
            className="w-[80vw] md:w-[50vw] mx-auto py-4 px-2"
            variants={itemVariants}
          >
            <motion.div
              className="flex flex-col md:flex-row justify-between items-center space-y-8 md:ring-1 md:ring-red-600 md:space-y-0 md:bg-transparent md:space-x-8 md:backdrop-filter md:backdrop-blur-md md:py-6 md:px-8 md:rounded-lg md:shadow-md"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <motion.div
                className="text-center"
                whileHover={{ y: -5 }}
              >
                <p className="text-3xl md:text-4xl font-bold text-[#4B0082]">28+</p>
                <p className="text-base md:text-lg text-gray-600">Years Experience</p>
              </motion.div>
              <motion.div
                className="text-center"
                whileHover={{ y: -5 }}
              >
                <div className="flex items-center justify-center mb-1">
                  <Star className="w-6 h-6 md:w-8 md:h-8 text-yellow-400 fill-current" />
                  <span className="text-2xl md:text-3xl font-bold ml-2">4.5</span>
                </div>
                <div className="flex items-center justify-center">
                  <span className="text-2xl md:text-3xl font-bold">
                    <span className="text-blue-600">G</span>
                    <span className="text-red-600">o</span>
                    <span className="text-yellow-600">o</span>
                    <span className="text-blue-600">g</span>
                    <span className="text-green-600">l</span>
                    <span className="text-red-600">e</span>
                  </span>
                </div>
              </motion.div>
              <motion.div
                className="text-center"
                whileHover={{ y: -5 }}
              >
                <Award className="w-10 h-10 md:w-12 md:h-12 text-[#4B0082] mx-auto mb-2" />
                <p className="text-base md:text-lg text-gray-600">APJ Abdul Kalam Award</p>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.section >


        {/* services section */}
        <ServicesPreview />

        <BlogPreview />


        {/* testimonials */}
        < TestimonialCarousel />

        {/* form contact & map */}
        < motion.section
          id="contact-info"
          className="relative  min-h-screen flex flex-col md:flex-row items-center justify-center py-16 px-4 md:px-8"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          {/* Background Image */}
          <motion.div
            className="absolute inset-0 z-0 shadow-xl"
            style={{
              backgroundImage: "url('/images/contact-home.jpg')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "brightness(0.7)"
            }}
          />

          {/* Content Container */}
          < motion.div
            className="relative z-10 w-full max-w-7xl mx-auto" variants={containerVariants} >
            <motion.div className="grid grid-cols-1 lg:grid-cols-2 gap-8">


              {/* Google Map Section */}
              <motion.div className="bg-white bg-opacity-90 ring-1 ring-red-600 rounded-lg shadow-xl overflow-hidden order-2">
                <motion.div
                  className="w-full h-[80vh]"
                  variants={itemVariants}
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112041.65068430085!2d77.10561899366593!3d28.65068726822101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d037f56c9ecb1%3A0xb301952fee34313b!2sDr.%20Kanika%20Jain!5e0!3m2!1sen!2sin!4v1725701873893!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen={false}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Dr. Kanika Jain Location"
                  ></iframe>
                </motion.div>
              </motion.div>

              {/* Form Section */}
              <motion.div className="bg-white/70 bg-opacity-90 ring-1 ring-red-600 rounded-lg shadow-xl overflow-hidden order-1">
                <motion.div
                  className="p-8 md:p-12"
                  variants={itemVariants}
                >
                  <h2 className="text-3xl text-center mb-6 text-purple-800 font-bold">Book Appointment</h2>
                  {state.succeeded ? (
                    <p className="text-indigo-700 font-work-sans">Thank you for your message. We'll get back to you soon!</p>
                  ) : (
                    <form onSubmit={handleFinalSubmit} className="space-y-4 font-work-sans">
                      <motion.div variants={itemVariants}>
                        <label htmlFor="name" className="block text-sm font-medium text-purple-700">Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="A Kumar"
                          required
                          className="mt-1 block w-full border border-purple-300 rounded-md shadow-sm p-2 bg-white text-purple-900 focus:ring-2 focus:ring-purple-400 focus:border-transparent"
                        />
                      </motion.div>
                      <motion.div variants={itemVariants}>
                        <label htmlFor="email" className="block text-sm font-medium text-purple-700">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          placeholder="youremail@domain.com"
                          className="mt-1 block w-full border border-purple-300 rounded-md shadow-sm p-2 bg-white text-purple-900 focus:ring-2 focus:ring-purple-400 focus:border-transparent"
                        />
                        <ValidationError prefix="Email" field="email" errors={state.errors} />
                      </motion.div>
                      <div>
                        <label className="block text-sm font-medium text-purple-700 mb-2">Appointment Type</label>
                        <div className="flex flex-wrap gap-4">
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              name="appointmentType"
                              value="offline"
                              checked={formData.appointmentType === 'offline'}
                              onChange={handleChange}
                              className="form-radio text-purple-600 focus:ring-purple-500 h-4 w-4"
                            />
                            <span className="ml-2 text-purple-700">Offline Appointment</span>
                          </label>
                          <label className="inline-flex items-center">
                            <input
                              type="radio"
                              name="appointmentType"
                              value="online"
                              checked={formData.appointmentType === 'online'}
                              onChange={handleChange}
                              className="form-radio text-purple-600 focus:ring-purple-500 h-4 w-4"
                            />
                            <span className="ml-2 text-purple-700">Online Appointment</span>
                          </label>
                        </div>
                      </div>
                      <motion.div variants={itemVariants}>
                        <label htmlFor="phone" className="block text-sm font-medium text-purple-700">Phone</label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                          placeholder="XXX-XXXX"
                          className="mt-1 block w-full border border-purple-300 rounded-md shadow-sm p-2 bg-white text-purple-900 focus:ring-2 focus:ring-purple-400 focus:border-transparent"
                        />
                      </motion.div>
                      <motion.div variants={itemVariants}>
                        <label htmlFor="date" className="block text-sm font-medium text-purple-700">Date</label>
                        <input
                          type="date"
                          id="date"
                          name="date"
                          value={formData.date}
                          onChange={handleChange}
                          required
                          placeholder="Select Date"
                          className="mt-1 block w-full border border-purple-300 rounded-md shadow-sm p-2 bg-white text-purple-900 focus:ring-2 focus:ring-purple-400 focus:border-transparent"
                        />
                        <ValidationError prefix="Date" field="date" errors={state.errors} />
                      </motion.div>
                      <motion.div variants={itemVariants}>
                        <label htmlFor="message" className="block text-sm font-medium text-purple-700">Message</label>
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          rows={4}
                          required
                          className="mt-1 block w-full border border-purple-300 rounded-md shadow-sm p-2 bg-white text-purple-900 focus:ring-2 focus:ring-purple-400 focus:border-transparent"
                        ></textarea>
                      </motion.div>
                      <motion.div variants={itemVariants}>
                        <button
                          type="submit"
                          disabled={state.submitting}
                          className="w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-yellow-600 transition-colors duration-300"
                        >
                          Send Message
                        </button>
                      </motion.div>
                    </form>
                  )}
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div >
        </motion.section >


      </main >
    </>
  )
}

export default Home;
